import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';

function SalesforceLogin() {
    const langs = useLanguage();
    const lang = langs.salesforce;

    const handleSubmit = () => {
        const apiUrl = process.env.REACT_APP_SALESFORCE_URL
        console.log("apiUrl ", apiUrl);
        window.location.href = apiUrl;
      };

    return (
        <>
            {/* Header Box Start */}
            <section className="headerBox blue-bg">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 pt-2 pb-2 top">
                                <a href="#">
                                    <img src="images/logo.png" alt="Canara Bank Logo" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid langCol">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 bottom pt-1 pb-1 top">
                <SelectLang />
              </div>
            </div>
          </div>
        </div>
            </section>
            {/* Header Box End */}

            {/* Salesforce Login Box Start */}
            <section className="sfBox">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 d-flex inner align-items-center" style={{ background: '#F0FAFF' }}>
                                <div className="col-sm-6 sfBox">
                                    <img src="images/sf-login.png" alt="Salesforce Login" />
                                </div>
                                <div className="col-sm-6 sf-text">
                                    
                                    <a href="#" className="button" onClick={handleSubmit}>{lang.line2}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Salesforce Login Box End */}

            {/* Footer Box Start */}
            <footer className="footerBox mt-3">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 p-0">
                                <p>@2024, Canara Bank, All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Box End */}
        </>
    );
}

export default SalesforceLogin;
