/** @format */

import React, { useEffect } from "react";
import {
  Navigate,
  Outlet
} from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const params = new URLSearchParams(window.location.search);
  
    const empCode = params.get('emp_code') || localStorage.getItem('employeeCode');
    const empName = params.get('emp_name') || localStorage.getItem('employeeName');
    const empDpCode = params.get("dp_code") || localStorage.getItem("employeeDpCode") || localStorage.getItem("dp_code");
    const empBranchName = params.get('branch_name') || localStorage.getItem('employeeBranchName');

    console.log(location.pathname, "pathhhh")
  if (
    (location.pathname === "/QR" && empDpCode && empCode && empName) ||
    (location.pathname === "/phone-number" && empDpCode) ||
    localStorage.getItem("phone_number")
  ) {
    return <Outlet />;
  }
  else if(location.pathname === "/qr" && !empDpCode && !empCode && !empName){
    console.log("pathQR")
    return <Navigate to ="/employee-feedback" />
  }
     else {
    return <Navigate to="/" />;
  }
};

export default PrivateRoute;
