import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import axios from 'axios';
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Otp = () => {
  useEffect(() => {
    const fetchTransactionTypes = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/transaction-types`, {
          phone_number: localStorage.getItem('phone_number'),
        });
        setTransactionTypes(response.data.uniqueTransactionTypes);
      } catch (error) {
        console.error('Error fetching transaction types:', error);
      }
    };

    fetchTransactionTypes();
  }, []);

  const [selectedType, setSelectedType] = useState('');
  const [transactionTypes, setTransactionTypes] = useState([]);
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [showModal, setShowModal] = useState(false);
  const [checkboxes, setCheckboxes] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const langs = useLanguage();
  const lang = langs.otp;
  const feedbackType = location.state?.feedbackType;
  const phone_number = location.state?.mobile;
  const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleReset = () => {
    setOtp(['', '', '', '', '', '']); // Reset OTP state
  };

  const validateOtp = () => {
    return otp.every((digit) => digit !== '');
  };

  const validateUniqueID = async (uniqueKey) => {
    const searchParams = new URLSearchParams(window.location.search);
    const empCode = searchParams.get('emp_code')
    try {
      const feedbackType = location.state?.feedbackType || "general";
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-uniquekey`, {
        uniqueKey: uniqueKey,
        feedbackType : feedbackType,
        empCode :  empCode
      });
      console.log("response from validation",response)

      if (response.data.status === 'valid') {
        return true;
      } else if(response.data.status === 'invalid'){
        if(response.data.lastUpdated <1){
          // alert(lang.error.twice);
          navigate(`/feedback-response${location.search}`)
        }
        else{
          toast.error(lang.error.transaction)
        }
        return false;
      }
      else{
        // alert(lang.error.twice);
        navigate(`/feedback-response${location.search}`)
        return false;
      }
    } catch (error) {
      console.error('Error validating unique ID:', error);
      toast.error(lang.error.submitFeedback);
      return false;
    }
  };

  const verifyOtp = async () => {
    const dpCode = localStorage.getItem('dp_code')
    const uniqueKey = localStorage.getItem('uniqueKey');
    console.log("fpppp",dpCode)
    if (validateOtp()) {
      const otpString = otp.join(''); // Combine OTP array into a single string
      const body = {
        otp: otpString,
        phone_number: phone_number,
        dp_code : dpCode,
        uniqueKey : uniqueKey
      };
      try {
        const response = await axios.post(`${API_ENDPOINT}/api/verify-otp`, body);
        console.log(response.data);
        if (response.data.status === 'valid' && !response.data.blocked) {
          if (feedbackType === 'service') {
            setShowModal(true);
          } else {
            navigate(`/feedback${location.search}`, { state: { feedbackType: feedbackType } });
          }
        } 
        else if (response.data.expired){
          toast.error("The OTP is expired");
        }
        else if(response.data.blocked){
          toast.error("OTP max attempts exceeded. Please try again after 5 minutes");
        }
        else if (response.data.status === 'invalid') {
          toast.error(lang.error.otp);
        }
      } catch (error) {
        console.error('Error verifying OTP:', error);
        toast.error(lang.error.otp);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await verifyOtp();
  };

  const resendOtp = async () => {
    const uniqueKey = localStorage.getItem('uniqueKey');
    const requestBody = {
      phone_number: phone_number,
      uniqueKey : uniqueKey
    };
    const response = await axios.post(`${API_ENDPOINT}/api/verify-mobile`, requestBody);
    console.log("responsef rom",response.data)
    if (response.data.success) {
      toast.success(lang.line14);
    } 
    else if (response.data.limitExceeded){
      toast.error("You cannot send OTP again. Please try again after 5 mins");
    }
    else {
      toast.error(lang.error.error);
      console.error("An error occurred ");
    }
  };

  const handleRadioChange = (e) => {
    const val = e.target.value;
    setSelectedType(val);
    setCheckboxes(val);
    localStorage.setItem('transactionType', selectedType);
  };

  const handleNext = async () => {
    if (!selectedType) return;
  
    const phoneNumber = localStorage.getItem('phone_number');
    if (!phoneNumber) {
      console.error('Phone number not found in local storage');
      return;
    }
  
    try {
      const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;
      const requestBody = {
        transaction_type: selectedType, 
        phone_number: phoneNumber,
      };
  
      const response = await axios.post(`${API_ENDPOINT}/api/getUniqueKey`, requestBody);
      console.log(response)
      if (response.data.success) {
        const { uniqueKey } = response.data;
        console.log(uniqueKey)
        localStorage.setItem('transactionType', selectedType);
        localStorage.setItem('uniqueKey', uniqueKey);
        const isUniqueIDValid = await validateUniqueID(uniqueKey);
        if(!isUniqueIDValid){
          navigate(`/feedback-response${location.search}`)
        }
        else{
          navigate(`/feedback${location.search}`, { state: { feedbackType: 'service' } });
        } 
      } else {
        console.error('Failed to retrieve uniqueKey:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching uniqueKey:', error);
    }
  };

  return (
    <div>
      <section className="headerBox blue-bg">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pt-2 pb-2 top">
                <a href="#">
                  <img src="images/logo.png" alt="Canara Bank Logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid langCol">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 bottom pt-1 pb-1 top">
                <SelectLang />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mobileBox d-flex">
                <div className="col-sm-6 left">
                  <img src="images/otp-img.png" alt="OTP Image" />
                </div>
                <div className="col-sm-6 right">
                  <div className="inner" style={{ width: '100%' }}>
                    <h1>{lang.line1}</h1>
                    <p>{lang.line2}</p>
                    <form onSubmit={handleSubmit} className="otpInput">
  {otp.map((digit, index) => (
    <input
      key={index}
      id={`otp-${index}`}
      type="text"
      className="mob form-control text-center"
      maxLength="1"
      value={digit}
      onChange={(e) => handleChange(e, index)}
      pattern="[0-9]*"
      inputMode="numeric"
      autoComplete="one-time-code"
    />
  ))}

  <span onClick={handleReset}>
    <img src="images/cross.png" alt="Clear" height={"15px"} width={"15px"} />
  </span>
</form>

                    
                   
                   
                    <div className="mt-3 d-flex align-items-center">
                      <p className="resend mr-3">{lang.line3} <span style={{ cursor: 'pointer' }} onClick={resendOtp}>{lang.line13}</span></p>
                      
                    </div>

                    <button
                      type="submit"
                      className={`button btn btn-primary mt-3 ${!validateOtp() ? 'disabled' : ''}`}
                      disabled={!validateOtp()}
                      onClick={handleSubmit}
                    >
                      {lang.line4}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footerBox mt-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 p-0">
                <p>@2024, Canara Bank, All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {showModal && (
        <div className="backdrop">
          <div className="modal" style={{ display: 'block' }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">{transactionTypes.length > 0 && lang.line5}</h4>
                </div>
                <div className="modal-body">
                  {transactionTypes.length > 0 ? (
                    transactionTypes.map((type, index) => (
                      <div className="form-check" key={index}>
                        <input
                          type="radio"
                          className="form-check-input"
                          id={`service-type-${index}`}
                          name="service-type"
                          value={type}
                          checked={checkboxes === type}
                          onChange={handleRadioChange}
                        />
                        <label className="form-check-label" htmlFor={`service-type-${index}`}>
                          {type}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p>{lang.noTransactionTypes}</p>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>{lang.line11}</button>
                  {transactionTypes.length > 0 && <button type="button" className="btn btn-primary" disabled={!selectedType} onClick={handleNext}>{lang.line12}</button>}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Otp;
