// import React, { useState, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './style.css'; // Assuming you have a custom CSS file
// import { useNavigate } from 'react-router-dom';
// import SelectLang from './SelectLang';
// import useLanguage from '../languages/useLanguage';

// const FeedbackServiceFlow = () => {
//   const [isPopupVisible, setPopupVisible] = useState(true);
//   const [dpCode, setDpCode] = useState('');
//   const [inputValue, setInputValue] = useState('');
//   const langs = useLanguage();
//   const lang = langs.feedbackServiceFlow;
//   const navigate = useNavigate();

//   // Effect to ensure navigation is blocked until dpCode is set
//   useEffect(() => {
//     if (dpCode) {
//       setPopupVisible(false);
//     }
//   }, [dpCode]);

//   const handleGeneralFeedback = () => {
//     if (!dpCode) {
//       setPopupVisible(true);
//     } else {
//       navigate('/phone-number', { state: { feedbackType: 'general' } });
//     }
//   };

//   const handleServiceFeedback = () => {
//     if (!dpCode) {
//       setPopupVisible(true);
//     } else {
//       navigate('/phone-number', { state: { feedbackType: 'service' } });
//     }
//   };

//   const handleInputChange = (event) => {
//     setInputValue(event.target.value);
//   };

//   const handleSubmit = () => {
//     if (inputValue.trim()) {
//       localStorage.setItem('dp_code', inputValue);
//       setDpCode(inputValue);
//     }
//   };

//   return (
//     <div>
//       {/* Popup Modal */}
//       {isPopupVisible && (
//         <div className="popup-overlay">
//           <div className="popup-content">
//             <h5 >Please enter Branch Code</h5>
//             <div style={{height:"20px"}}></div>
//             <input
//               type="text"
//               value={inputValue}
//               onChange={handleInputChange}
//               placeholder="Branch Code"
//             />
//             <button onClick={handleSubmit}>Submit</button>
//           </div>
//         </div>
//       )}

//       <section className="headerBox blue-bg">
//         <div className="container-fluid">
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-12 pt-2 pb-2 top">
//                 <img src="images/logo.png" alt="Kiosk" className="img-fluid" />
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="container-fluid langCol">
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-12 bottom pt-1 pb-1 top">
//                 <SelectLang />
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Kios Box Section */}
//       <section className="kiosBox">
//         <div className="container-fluid">
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-12 d-flex inner align-items-center" style={{ background: '#F0FAFF' }}>
//                 <div className="col-sm-6 kiosImg">
//                   <img src="images/kiosimg.png" alt="Kiosk" />
//                 </div>

//                 <div className="col-sm-6 kiosText">
//                   <div className="button">
//                     <span className="d-flex align-items-center" onClick={handleGeneralFeedback}>
//                       <img src="images/feedback_icon1.svg" alt="General Feedback Icon" className="mx-1" /> {lang.line1}
//                     </span>
//                   </div>
//                   <div className="button">
//                     <span className="d-flex align-items-center" onClick={handleServiceFeedback}>
//                       <img src="images/feedback_icon1.svg" alt="Service Feedback Icon" className="mx-1" /> {lang.line2}
//                     </span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Footer Section */}
//       <footer className="footerBox mt-3">
//         <div className="container-fluid">
//           <div className="container">
//             <div className="row">
//               <div className="col-lg-12 p-0">
//                 <p>@2024, Canara Bank, All Rights Reserved.</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default FeedbackServiceFlow;


import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'; // Assuming you have a custom CSS file
import { useNavigate } from 'react-router-dom';
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import axios from 'axios'; // For API calls
import { encryptData,decryptData } from '../utils/cryptoUtils';

const FeedbackServiceFlow = () => {
  const [isPopupVisible, setPopupVisible] = useState(true);
  const encryptedDpCode = localStorage.getItem('dp_code');
  const decryptedDpCode = decryptData(encryptedDpCode);
  const [dpCode, setDpCode] = useState(decryptedDpCode || "");
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState(''); // For searching branch codes
  const [branchCodes, setBranchCodes] = useState([]); // Store branch codes from API
  const [filteredBranchCodes, setFilteredBranchCodes] = useState([]); 
  const [branchName, setBranchName] = useState(localStorage.getItem('branch_name') || "");
  const langs = useLanguage();
  const lang = langs.feedbackServiceFlow;
  const navigate = useNavigate();

  // Fetch branch codes from API when the component mounts
  useEffect(() => {
    const fetchBranchCodes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/branch-codes`); // Assuming the API endpoint is /branch-codes
        setBranchCodes(response.data);
      } catch (error) {
        console.error('Error fetching branch codes:', error);
      }
    };
    fetchBranchCodes();
  }, []);

  // Filter branch codes based on search term
  useEffect(() => {
    if (searchTerm) {
      const filtered = branchCodes.filter(branch => 
        (branch.name && branch.name.toLowerCase().includes(searchTerm.toLowerCase())) || 
        (branch.dpcd__c && String(branch.dpcd__c).includes(searchTerm))
      );
      console.log(filtered)
      setFilteredBranchCodes(filtered);
    } 
  }, [searchTerm, branchCodes]);

  // Effect to ensure navigation is blocked until dpCode is set
  useEffect(() => {
    let dp = null;
    try{
      const code = localStorage.getItem('dp_code');
      dp = decryptData(code)
    }
    catch(err){
      console.log("error decrypting dp code");
    }
    if (dp && branchName) {
      setPopupVisible(false);
    }
  }, [dpCode]);

  const handleGeneralFeedback = () => {
    if (!dpCode) {
      setPopupVisible(true);
    } else {
      navigate('/phone-number', { state: { feedbackType: 'general' } });
    }
  };

  const handleServiceFeedback = () => {
    if (!dpCode) {
      setPopupVisible(true);
    } else {
      navigate('/phone-number', { state: { feedbackType: 'service' } });
    }
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value); // Update search term on input change
  };

  const handleSelectBranchCode = (branch) => {
    setSearchTerm(`${branch.name} (${branch.dpcd__c})`);
    const dpCode = branch.dpcd__c;
    console.log("dpCode",dpCode)
    const encryptedDpCode = encryptData(dpCode);
    localStorage.setItem('dp_code', encryptedDpCode);
    localStorage.setItem('branch_name', branch.name);
    setDpCode(branch.dpcd__c); 
    setBranchName(branch.name)
  };

  return (
    <div>
      {/* Popup Modal */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h5>Please enter Branch Code</h5>
            <div style={{ height: '20px' }}></div>
            <input
              type="text"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search by Branch Name or Code"
            />
            <ul className="branch-list">
              {filteredBranchCodes.map((branch, index) => (
                <li key={index} onClick={() => handleSelectBranchCode(branch)}>
                  {branch.name} ({branch.dpcd__c})
                </li>
              ))}
            </ul>
            <button onClick={() => setPopupVisible(false)}>Close</button>
          </div>
        </div>
      )}

      <section className="headerBox blue-bg">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pt-2 pb-2 top">
                <img src="images/logo.png" alt="Kiosk" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid langCol">
          <div className="container">
            <div className="row">
            <p className="rate-services-title">
      Rate our Services: <span className="branch-details">{branchName} ({dpCode})</span>
    </p>
              <div className="col-lg-12 bottom pt-1 pb-1 top">
                <SelectLang />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Kios Box Section */}
      <section className="kiosBox">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 d-flex inner align-items-center" style={{ background: '#F0FAFF' }}>
                <div className="col-sm-6 kiosImg">
                  <img src="images/kiosimg.png" alt="Kiosk" />
                </div>

                <div className="col-sm-6 kiosText">
                  <div className="button">
                    <span className="d-flex align-items-center" onClick={handleGeneralFeedback}>
                      <img src="images/feedback_icon1.svg" alt="General Feedback Icon" className="mx-1" /> {lang.line1}
                    </span>
                  </div>
                  <div className="button">
                    <span className="d-flex align-items-center" onClick={handleServiceFeedback}>
                      <img src="images/feedback_icon1.svg" alt="Service Feedback Icon" className="mx-1" /> {lang.line2}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footerBox mt-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 p-0">
                <p>@2024, Canara Bank, All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FeedbackServiceFlow;

