// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loader.css */
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 5px solid #ddd;
    border-top-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.loader-container p {
    margin-top: 10px;
    color: #333;
    font-size: 1.2em;
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,0CAA0C;IAC1C,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,kCAAkC;AACtC;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":["/* Loader.css */\n.loader-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(255, 255, 255, 0.8);\n    z-index: 1000;\n}\n\n.spinner {\n    width: 40px;\n    height: 40px;\n    border: 5px solid #ddd;\n    border-top-color: #007bff;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    to {\n        transform: rotate(360deg);\n    }\n}\n\n.loader-container p {\n    margin-top: 10px;\n    color: #333;\n    font-size: 1.2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
