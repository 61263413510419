import english from "./english";
import hindi from "./hindi";
import tamil from './tamil';
import telugu from './telugu';
import malyalam from './malyalam';
import urdu from './urdu';
import kannada from './kannada';
import odiya from './odiya';
import bengali from './bengali';
import punjabi from './punjabi';

const languages = {
    english,
    hindi,
    tamil,
    telugu,
    malyalam,
    urdu,
    kannada,
    odiya,
    bengali,
    punjabi
  };

export default languages;  