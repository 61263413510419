import React, { useRef, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import QRCode from 'react-qr-code';
import SelectLangQR from './SelectLangQR';
import useLanguage from '../languages/useLanguage';
import './QR.css';
import { toPng } from 'html-to-image';
import { saveAs } from 'file-saver';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';

const QR = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
    const code = localStorage.getItem('salesforceCode');
    if(!code){
      if (!code) {
        setLoading(true); // Show loader during redirection
        setTimeout(() => {
            navigate('/employee-feedback');
        }, 3000); // Set timeout for 3 seconds to show loader before redirect
    }
    }
  },[]);
  const langs = useLanguage();
  const lang = langs.qr;
  const lang2 = langs.employee;
  
  // State for employee details from URL params
  const [empDetails, setEmpDetails] = useState({
    empCode: '',
    empName: '',
    empDpCode: '',
    empBranchName : ''
  });

  const downloadRef = useRef(null);

  // Fetch query parameters and set them in state
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const empCode = params.get('emp_code') || localStorage.getItem('employeeCode');
    const empName = params.get('emp_name') || localStorage.getItem('employeeName');
    const empDpCode = params.get('dp_code') || localStorage.getItem('employeeDpCode');
    const empBranchName = params.get('branch_name') || localStorage.getItem('employeeBranchName');
    setEmpDetails({ empCode, empName, empDpCode,empBranchName });
  }, []);

  const handleDownloadImage = () => {
    if (downloadRef.current === null) {
      return;
    }

    const empNameElement = document.querySelector('.emp-name');
    // if (empNameElement) empNameElement.style.display = 'block';

    const langComponent = document.querySelector('.langCol');
    const downloadButton = document.querySelector('.download-button');
    if (langComponent) langComponent.style.display = 'none';
    if (downloadButton) downloadButton.style.display = 'none';

    toPng(downloadRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const fileName = `${empDetails.empName || 'QRCodePage'}.png`; // Use employee name in filename
        saveAs(dataUrl, fileName);
      })
      .catch((err) => {
        console.error('Failed to download image:', err);
      })
      .finally(() => {
        if (langComponent) langComponent.style.display = '';
        if (downloadButton) downloadButton.style.display = '';
      });
  };
  
  return (
    <div>
      <section className="qrPage">   
        <div className="container-fluid">
          <section className="headerBox">
            <div className="centered-fluid">
              <div className="centered">
                <div className="row">

               
                  <div className="col-lg-12 bottom pt-1 pb-1 top langCol">
                  
                    <SelectLangQR />
                    <button
                      className="btn btn-link p-0 download-button"
                      onClick={handleDownloadImage}
                      style={{ border: 'none', background: 'transparent' }}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2V16M12 16L8 12M12 16L16 12M4 18H20" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          <div className="centered" ref={downloadRef}>
          <div  >
   
  </div>
            <div className="row inner">
              <div className="col-sm-12">
                <a href="#" className="logo"><img src="images/logo.png" alt="Logo" width="200px" /></a>
              </div>
              
              <div className="col-sm-12 pBox">
              <div className="emp-name" >
    <p style={{fontSize:'16px'}}>{empDetails.empName}</p>
    <p style={{fontSize:'14px'}} className="dpCode">{empDetails.empBranchName} ({empDetails.empDpCode})</p>
  </div>
                <p className="qouteIcon">
                  <p>{lang}</p>
                  Dear Customers, Your feedback matters! Kindly submit your feedback on branch banking experience to help us serve you better.
                </p>
              </div>

              <div className="col-sm-4 qrGirl">
                <img src="images/qr_girl.png" alt="QR Girl" />
              </div>
              <div className="col-sm-8 qrCode">
                <div className="qr-wrapper">
                <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`${process.env.REACT_APP_WEB_BASE_URL}phone-number?emp_code=${empDetails.empCode}&emp_name=${empDetails.empName}&dp_code=${empDetails.empDpCode}&branch_name=${empDetails.empBranchName}`}
                  />
                  <div className="blue-label">
                    <span>Your feedback matters</span><br />
                    <span>Scan Here</span>
                  </div>
                </div>
                <p>
                  {/* <a style={{fontSize:"12px"}}>www.canarabank.com</a><br />
                  <span style={{fontSize:"12px"}}>hocss1@canarabank.com</span> */}
                </p>
                <div style={{ height: "8px" }}></div>
              </div>
            </div>
          </div>
        </div>
        {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                    <p>{lang2.error.error4}</p>
                </div>
            )}
        <footer className="footerBox footerBox1 p-0" style={{ background: 'none' }}>
          <div className="centered-fluid">
            <div className="centered">
              <div className="row">
                <div className="col-lg-12 footercol">
                  <a href="#" className="call">
                    <img src="images/call.png" alt="Call" style={{ marginRight: '10px' }} /> 9076030001
                  </a>
                  <ul>
                    <li><a href="#"><img src="images/fb.svg" alt="Facebook" width="24px" /></a></li>
                    <li><a href="#"><img src="images/insta.svg" alt="Instagram" width="24px" /></a></li>
                    <li><a href="#"><img src="images/linkedin.svg" alt="LinkedIn" width="24px" /></a></li>
                    <li><a href="#"><img src="images/youtube.svg" alt="YouTube" width="24px" /></a></li>
                  </ul>
                  <img src="images/bankNo.png" alt="Bank No" width="150px" />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
};

export default QR;
