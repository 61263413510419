import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY;
export const encryptData = (data) => {
    if(!data) return data;
    // console.log("data at encrypt func",data)
    try{
      console.log(typeof data)
      const stringData = typeof data === 'string' ? data : JSON.stringify(data);
      return CryptoJS.AES.encrypt(stringData, secretKey).toString();
    }
    catch(err){
      console.log("error heree",err)
      return null;
    }
  };

export const decryptData = (encryptedData) => {
  console.log("data at decrypt func",encryptedData)
    if(!encryptedData) return encryptedData;
    try{
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    }
    catch(err){
      return null;
    }  
};