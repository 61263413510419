import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import { toast } from 'react-toastify';
import { decryptData, encryptData} from '../utils/cryptoUtils';

const Mobile = () => {
  const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;
  const [mobile, setMobile] = useState('');
  const [error, setError] = useState('');
  const empCodeStorage = localStorage.getItem('emp_code');
  const empCodeDecStorage = decryptData(empCodeStorage);
  const [empCode, setEmpCode] = useState(empCodeDecStorage);
  const navigate = useNavigate();
  const location = useLocation();
  const objs = useParams(); 
  const feedbackType = location.state?.feedbackType;
  const langs = useLanguage();
  const lang = langs.mobile;
  // useEffect(()=>{
  //   const searchParams = new URLSearchParams(window.location.search);
  //   // console.log(searchParams.get('emp_code1'))
  //   setEmpCode(searchParams.get('emp_code'))
  // },[]);
  const validateMobile = (number) => {
    const mobilePattern = /^[0-9]{10}$/;
    return mobilePattern.test(number);
  };
  
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const empCodeFromURL = searchParams.get('emp_code'); // Example query parameter
    const dpCodeFromURL = searchParams.get('dp_code')
    const data = searchParams.get('data');
    console.log("empCodeFromURL",empCodeFromURL);
    console.log("dpCodeFromURL",dpCodeFromURL)
    if (empCodeFromURL) {
      const encryptedEmpCode = encryptData(empCodeFromURL);
      console.log("encryptedEmpCode",encryptedEmpCode);
      localStorage.setItem('emp_code', encryptedEmpCode);
      setEmpCode(empCodeFromURL);
    }
    if(dpCodeFromURL){
      const encryptedDpCode = encryptData(dpCodeFromURL);
      console.log("encryptedDpCode",encryptedDpCode);
      localStorage.setItem('dp_code', encryptedDpCode);
    }
    if(data){
      const decryptedEmpDetails = decryptData(data);
      console.log("decryptedEmpDetails",decryptedEmpDetails);
      const splitted = decryptedEmpDetails.split('|');
      const plainDpCode = splitted[1];
      const plainEmpCode = splitted[0];
      const encryptedEmpCode = encryptData(plainEmpCode);
      console.log("encryptedEmpCode",encryptedEmpCode);
      localStorage.setItem('emp_code', encryptedEmpCode);
      setEmpCode(plainEmpCode);
      const encryptedDpCode = encryptData(plainDpCode);
      console.log("encryptedDpCode",encryptedDpCode);
      localStorage.setItem('dp_code', encryptedDpCode);
    }
    // Remove query parameters from URL
    const newUrl = window.location.pathname;
    window.history.replaceState(null, '', newUrl);
  }, [location]);

  const validateUniqueID = async (uniqueKey) => {
    try {
      const feedbackType = location.state?.feedbackType || "general";
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-uniquekey`, {
        uniqueKey: uniqueKey,
        feedbackType : feedbackType,
        empCode : empCode
      });
      console.log("response from validation",response)

      if (response.data.status === 'valid') {
        return true;
      } else if(response.data.status === 'invalid'){
        if(response.data.lastUpdated <1){
          // alert(lang.error.twice);
          navigate('/feedback-response')
        }
        else{
          toast.error(lang.error.transaction)
        }
        return false;
      }
      else{
        // alert(lang.error.twice);
        navigate('/feedback-response')
        return false;
      }
    } catch (error) {
      console.error('Error validating unique ID:', error);
      toast.error(lang.error.submitFeedback);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!validateMobile(mobile)) {
      setError('Please enter a valid 10-digit mobile number.');
      return;
    }
    const feedbackType = empCode ? "employee" : location.state?.feedbackType || "general";
    try {
      const customerDetailsRequestBody = {
        phone_number: mobile,
        feedback_type : feedbackType,
        emp_id : empCode
      };
      console.log("customerDetailsRequestBody",customerDetailsRequestBody)
      const customerDetailsResponse = await axios.post(`${API_ENDPOINT}/api/getCustomerDetails`, customerDetailsRequestBody);
      console.log("custimerr",customerDetailsResponse)
      if (customerDetailsResponse.data.success) {
        const { uniqueKey, customer_id, dp_code } = customerDetailsResponse.data;
  
        localStorage.setItem('uniqueKey', uniqueKey);
        localStorage.setItem('customer_id', customer_id);
        localStorage.setItem('phone_number', mobile);
  
        const isUniqueIDValid = await validateUniqueID(uniqueKey);
  
        if (!isUniqueIDValid && feedbackType !== 'service') {
          navigate('/feedback-response');
        } else if (feedbackType === 'service') {
          let transactionTypes;
          const fetchTransactionTypes = async () => {
            try {
              const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/transaction-types`, {
                phone_number: mobile,
                uniqueKey : uniqueKey
              });
              transactionTypes = response.data.uniqueTransactionTypes;
            } catch (error) {
              console.error('Error fetching transaction types:', error);
            }
          };
  
          await fetchTransactionTypes();
          if (!transactionTypes || transactionTypes.length <= 0) {
            navigate('/feedback-response-service');
          } else {
            const verifyMobileRequestBody = {
              phone_number: mobile,
            };
  
            const otpResponse = await axios.post(`${API_ENDPOINT}/api/verify-mobile`, verifyMobileRequestBody);
            console.log("responsef rom",otpResponse.data)
            if (otpResponse.data.success) {
              console.log(otpResponse.data.otp);
              if(empCode){
                // navigate(`/otp-verification${location.search}`, { state: { feedbackType : 'employee', mobile } });
                navigate(`/otp-verification`, { state: { feedbackType : 'employee', mobile } });
              }
              else{
                // navigate(`/otp-verification${location.search}`, { state: { feedbackType, mobile } });
                navigate(`/otp-verification`, { state: { feedbackType, mobile } });
              }
              
            }
            else if (otpResponse.data.limitExceeded){
              toast.error("You cannot send OTP again. Please try again after 5 mins");
            }
             else {
              setError(lang.error.otp);
            }
          }
        } else {
          const verifyMobileRequestBody = {
            phone_number: mobile,
            uniqueKey : uniqueKey
          };
  
          const otpResponse = await axios.post(`${API_ENDPOINT}/api/verify-mobile`, verifyMobileRequestBody);
          console.log("responsef rom",otpResponse.data)
          if (otpResponse.data.success) {
            console.log(otpResponse.data.otp);
            if(empCode){
              // navigate(`/otp-verification${location.search}`, { state: { feedbackType : 'employee', mobile } });
              navigate(`/otp-verification`, { state: { feedbackType : 'employee', mobile } });
            }
            else if (otpResponse.data.limitExceeded){
              toast.error("You cannot send OTP again. Please try again after 5 mins");
            }
            else{
              // navigate(`/otp-verification${location.search}`, { state: { feedbackType, mobile } });
              navigate(`/otp-verification`, { state: { feedbackType, mobile } });
            }
            
          } else {
            setError(lang.error.otp);
          }
        }
      } else {
        setError('Failed to fetch customer details.');
      }
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(lang.error.error);
    }
  };
  

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!validateMobile(mobile)) {
  //     setError('Please enter a valid 10-digit mobile number.');
  //     return;
  //   }

  //   try {
  //     //with encryption

  //     // const requestBody = {
  //     //   Request: {
  //     //     body: {
  //     //       encryptData: {
  //     //         phone_number : mobile
  //     //       }
  //     //     }
  //     //   }
  //     // };

  //     //without encryption
  //     // const searchParams = new URLSearchParams(location.search);
  //     // const id = searchParams.get('id');
  //     console.log(feedbackType)
  //     const requestBody = {
  //       phone_number : mobile,
        
  //     }
  //     const response = await axios.post(`${API_ENDPOINT}/api/verify-mobile`, requestBody);
      
  //     if (response.data.success) {
  //       // navigate('/otp-verification', { state: { feedbackType,mobile } });
  //       localStorage.setItem('uniqueKey', response.data.uniqueKey);
  //       localStorage.setItem('customer_id', response.data.customer_id);
  //       localStorage.setItem('dp_code', response.data.dp_code);
  //       localStorage.setItem('phone_number', mobile);
  //       console.log(response.data.otp)
  //       const uniqueKey = response.data.uniqueKey
  //       const isUniqueIDValid = await validateUniqueID(uniqueKey);
        
  //       if(!isUniqueIDValid && feedbackType !== 'service'){
  //         navigate('/feedback-response')
  //       }
  //       else if(feedbackType === 'service'){
  //         let transactionTypes;
  //         const fetchTransactionTypes = async () => {
  //           try {
  //             const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/transaction-types`, {
  //               phone_number: mobile,
  //             });
  //             transactionTypes = response.data.uniqueTransactionTypes;
  //           } catch (error) {
  //             console.error('Error fetching transaction types:', error);
  //           }
  //         };
  //         await fetchTransactionTypes();
  //         if(transactionTypes && transactionTypes.length <= 0){
  //           navigate('/feedback-response-service')
  //         }
  //         else{
  //           navigate('/otp-verification', { state: { feedbackType,mobile } });
  //         }
  //       }
  //       else{
  //         navigate('/otp-verification', { state: { feedbackType,mobile } });
  //       }
  //     } else {
  //       setError(lang.error.otp);
  //     }
  //   } catch (err) {
  //     console.error('Error sending OTP:', err);
  //     setError(lang.error.error);
  //   }
  // };

  return (
    <div>
      {/* Header Section */}
      <section className="headerBox blue-bg">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pt-2 pb-2 top">
                <a href="#">
                  <img src="images/logo.png" alt="Canara Bank Logo" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid langCol">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 bottom pt-1 pb-1 top">
              <SelectLang/>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile Number Section */}
      <section>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mobileBox">
                <div className="row">
                  <div className="col-sm-6 left">
                    <img src="images/mobileNo.png" alt="Mobile Number" />
                  </div>

                  <div className="col-sm-6 right">
                    <div className="inner">
                      <h1>{lang.line1}</h1>
                      <p>{lang.line2}</p>
                      <form onSubmit={handleSubmit}>
                        <label htmlFor="mobile">{lang.line3}</label>
                        <input
                          type="text"
                          className="mob1"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                        <button
                          type="submit"
                          className={`button ${!validateMobile(mobile) ? 'disabled' : ''}`}
                          disabled={!validateMobile(mobile)}
                        >
                          {lang.line4}
                        </button>
                      </form>
                      {error && <p className="text-danger">{error}</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footerBox mt-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 p-0">
                <p>@2024, Canara Bank, All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Mobile;
