const kannada = {
    feedbackServiceFlow: {
        line1: "ಸಾಮಾನ್ಯ ಪ್ರತಿಕ್ರಿಯೆ",
        line2: "ಸೇವಾ ಮಟ್ಟದ ಪ್ರತಿಕ್ರಿಯೆ"
    },
    mobile: {
        line1: "ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ",
        line2: "ನಾವು ನಿಮಗೆ ಒಂದು ದೃಢೀಕರಣ ಕೋಡ್ ಕಳುಹಿಸುತ್ತೇವೆ",
        line3: "ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ",
        line4: "ಮುಂದುವರಿಸಿ",
        error: {
            error: "ಒಂದು ದೋಷ ಉಂಟಾಯಿತು. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ",
            otp: "OTP ಕಳುಹಿಸುವಲ್ಲಿ ವಿಫಲವಾಗಿದೆ. ದಯವಿಟ್ಟು ಮತ್ತೊಮ್ಮೆ ಪ್ರಯತ್ನಿಸಿ."
        }
    },
    otp: {
        line1: "ದೃಢೀಕರಣ ಕೋಡ್",
        line2: "ನಾವು ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಗೆ ದೃಢೀಕರಣ ಕೋಡ್ ಕಳುಹಿಸಿದ್ದೇವೆ",
        line3: "OTP ಸಿಗಲಿಲ್ಲವೇ?",
        line4: "ಮುಂದುವರಿಸಿ",
        line5: "ಪ್ರತಿಕ್ರಿಯೆಗೆ ಸೇವಾ ಪ್ರಕಾರ ಆಯ್ಕೆಮಾಡಿ",
        line6: "ನಗದು ಠೇವಣಿ",
        line7: "ಹಣ ವರ್ಗಾವಣೆ",
        line8: "ಖಾತೆ ತೆರೆಯುವುದು",
        line9: "SB ಖಾತೆ ಸಂಬಂಧಿತ ಸೇವೆಗಳು",
        line10: "ಪ್ರಸ್ತುತ ಖಾತೆ",
        line11: "ರದ್ದುಮಾಡಿ",
        line12: "ಮುಂದೆ",
        line13: "OTP ಮತ್ತೆ ಕಳುಹಿಸಿ",
        line14: "OTP ಯಶಸ್ವಿಯಾಗಿ ಕಳುಹಿಸಲಾಗಿದೆ",
        noTransactionTypes: "ಯಾವುದೇ ಸೇವೆಯನ್ನು ಬಳಸದೆ ನೀವು ಪ್ರತಿಕ್ರಿಯೆ ಸಲ್ಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ",
        error: {
            error: "OTP ಕಳುಹಿಸುವಾಗ ದೋಷವಾಯಿತು. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
            otp: "ಅಮಾನ್ಯ OTP"
        }
    },
    feedback: {
        line1: "ದಯವಿಟ್ಟು ಇಂದು ನಮ್ಮ ಜೊತೆಗಿನ ನಿಮ್ಮ ಅನುಭವವನ್ನು ರೇಟ್ ಮಾಡಿ",
        verypoor: "ತುಂಬಾ ಕೆಟ್ಟದು",
        belowAverage: "ಸಾಮಾನ್ಯಕ್ಕಿಂತ ಕಡಿಮೆ",
        average: "ಸಾಮಾನ್ಯ",
        good: "ಚೆನ್ನಾಗಿದೆ",
        veryGood: "ತುಂಬಾ ಚೆನ್ನಾಗಿದೆ",
        excellent: "ಅತ್ಯುತ್ತಮ",
        outstanding: "ಅದ್ಭುತ",
        poorFeedbackHeader: "ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ, ದಯವಿಟ್ಟು ಅಸಮಾಧಾನದ ಕಾರಣವನ್ನು ವಿವರಿಸಿ:",
        badService: "ಕೆಟ್ಟ ಸೇವೆ",
        notGoodSupport: "ಗ್ರಾಹಕ ಬೆಂಬಲ ಚೆನ್ನಾಗಿಲ್ಲ",
        others: "ಇತರೆ",
        textareaPlaceholder: "ನಾವು ವಿಷಾದಿಸುತ್ತೇವೆ",
        captchaPlaceholder: "ಕ್ಯಾಪ್ಚಾ ನಮೂದಿಸಿ",
        reload: "ಕ್ಯಾಪ್ಚಾ ಮರು ಲೋಡ್ ಮಾಡಿ",
        submit: "ಸಲ್ಲಿಸು",
        work: "ಕೆಲಸ ಸಮಯಕ್ಕೆ ಪೂರ್ಣಗೊಳ್ಳಲಿಲ್ಲ",
        inadequate: "ಸಿಬ್ಬಂದಿಗೆ ಉತ್ಪನ್ನ/ಸೇವೆಯ ಕುರಿತು ಪರ್ಯಾಯ ಜ್ಞಾನವಿಲ್ಲ",
        guidance: "ಕೆಲಸ ಮಾಡಿಸಲು ಸರಿಯಾದ ಮಾರ್ಗದರ್ಶನ ನೀಡಲಾಗಿಲ್ಲ",
        staff: "ಸಿಬ್ಬಂದಿಯ ನಡವಳಿಕೆ",
        products: "ಉತ್ಪನ್ನಗಳು/ಪ್ರಕ್ರಿಯೆಗಳು ಗ್ರಾಹಕರ ಅಗತ್ಯಗಳನ್ನು ಪೂರೈಸಲು ಸೂಕ್ತವಲ್ಲ",
        other: "ಇನ್ಯಾವುದೇ",
        general1: "ಕೆಲಸ ತೃಪ್ತಿಕರವಾಗಿ ಪೂರ್ಣಗೊಂಡಿಲ್ಲ",
general2: "ಉತ್ಪನ್ನ/ಸೇವೆಯ ಬಗ್ಗೆ ಸಿಬ್ಬಂದಿಗೆ ಪರ್ಯಾಯ ಜ್ಞಾನವಿಲ್ಲ",
general3: "ಕೆಲಸವನ್ನು ಮುಗಿಸಲು ಸೂಕ್ತ ಮಾರ್ಗದರ್ಶನ ನೀಡಲಾಗಿಲ್ಲ",
general4: "ಸಿಬ್ಬಂದಿಯ ವರ್ತನೆ",
general5: "ಉತ್ಪನ್ನಗಳು/ಪ್ರಕ್ರಿಯೆಗಳು ಗ್ರಾಹಕರ ಅಗತ್ಯಗಳನ್ನು ಪೂರೈಸಲು ಸೂಕ್ತವಲ್ಲ",
general6: "ಇನ್ನಾವುದೇ",
        error: {
            selectRating: "ದಯವಿಟ್ಟು ರೇಟಿಂಗ್ ಆಯ್ಕೆಮಾಡಿ.",
            incorrectCaptcha: "ಕ್ಯಾಪ್ಚಾ ಸರಿಯಿಲ್ಲ.",
            submitFeedback: "ಮೂಡಣಿಕೆಯಿಲ್ಲದೆ ನೀವು ಪ್ರತಿಕ್ರಿಯೆ ನೀಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
            twice: "ನೀವು ಎರಡು ಬಾರಿ ಪ್ರತಿಕ್ರಿಯೆ ನೀಡಲು ಸಾಧ್ಯವಿಲ್ಲ",
            transaction: "ಯಾವುದೇ ವ್ಯವಹಾರ ಮಾಡದೆ ಮೊದಲು ನೀವು ಪ್ರತಿಕ್ರಿಯೆ ನೀಡಲು ಸಾಧ್ಯವಿಲ್ಲ"
        }
    },
    thankYou: {
        line1: "ಪ್ರತಿಕ್ರಿಯೆಗೆ ಧನ್ಯವಾದಗಳು,",
        line2: "ನಾವು ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ಮೌಲ್ಯವಾಗಿಸು"
    },
    feedbackMessage: {
        line1: "ನಾವು ಈಗಾಗಲೇ ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆ ಪಡೆಯಿದ್ದೇವೆ",
        line2: "ನೀವು ಎರಡನೇ ಬಾರಿ ಸಲ್ಲಿಸಲು ಸಾಧ್ಯವಿಲ್ಲ"
    },
    employee: {
        line1: "ಕರ್ಯನಿರ್ವಹಕ ಕೋಡ್ ನಮೂದಿಸಿ",
        line2: "ದಯವಿಟ್ಟು ನಿಮ್ಮ ಕಾರ್ಯನಿರ್ವಹಕ ಕೋಡ್ ನಮೂದಿಸಿ",
        line3: "ಕರ್ಯನಿರ್ವಹಕ ಕೋಡ್",
        line4: "ಸಲ್ಲಿಸು",
        error: {
            error1: "ಕರ್ಯನಿರ್ವಹಕ ಕೋಡ್ ಖಾಲಿಯಾಗಿರಬಾರದು",
            error2 : "ಓಹ್! ಏನೋ ತಪ್ಪಾಗಿದೆ. ದಯವಿಟ್ಟು ನಿರ್ವಾಹಕರನ್ನು ಸಂಪರ್ಕಿಸಿ.",
            error3: "ಲಾಗಿನ್ ವಿವರಗಳನ್ನು ಪಡೆದುಕೊಳ್ಳುವಲ್ಲಿ ವಿಫಲವಾಗಿದೆ",
            error4 : "ನೀವು ಪ್ರಮಾಣೀಕೃತವಿಲ್ಲ. ದಯವಿಟ್ಟು ಮೊದಲು ಲಾಗಿನ್ ಮಾಡಿ",
            error5: "ಈ ಐಡಿಯನ್ನು ಹೊಂದಿರುವ ಕಾರ್ಯನಿರ್ವಹಕನನ್ನು ಹುಡುಕಲಾಗಲಿಲ್ಲ"
        }
    },
    salesforce: {
        line1: "ಸೆಲ್ಸ್ಫೋರ್ಸ್ ಬಳಸಿ ಲಾಗ್ ಇನ್ ಮಾಡಿ",
        line2: "ಸೆಲ್ಸ್ಫೋರ್ಸ್ ಬಳಸಿ ಲಾಗ್ ಇನ್ ಮಾಡಿ"
    },
    qr : "ಆತ್ಮೀಯ ಗ್ರಾಹಕರೇ, ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆ ಮುಖ್ಯವಾಗಿದೆ! ನಿಮಗೆ ಉತ್ತಮವಾಗಿ ಸೇವೆ ಸಲ್ಲಿಸಲು ನಮಗೆ ಸಹಾಯ ಮಾಡಲು ಶಾಖೆಯ ಬ್ಯಾಂಕಿಂಗ್ ಅನುಭವದ ಕುರಿತು ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆಯನ್ನು ದಯವಿಟ್ಟು ಸಲ್ಲಿಸಿ."
}

export default kannada;
