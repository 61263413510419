const punjabi = {
    feedbackServiceFlow: {
        line1: "ਸਧਾਰਣ ਫੀਡਬੈਕ",
        line2: "ਸੇਵਾ ਸਤਰ ਫੀਡਬੈਕ"
    },
    mobile: {
        line1: "ਆਪਣਾ ਮੋਬਾਈਲ ਨੰਬਰ ਦਰਜ ਕਰੋ",
        line2: "ਅਸੀਂ ਤੁਹਾਨੂੰ ਇੱਕ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਭੇਜਾਂਗੇ",
        line3: "ਮੋਬਾਈਲ ਨੰਬਰ ਦਰਜ ਕਰੋ",
        line4: "ਜਾਰੀ ਰੱਖੋ",
        error: {
            error: "ਕੋਈ ਗਲਤੀ ਹੋਈ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
            otp: "OTP ਭੇਜਣ ਵਿੱਚ ਅਸਫਲਤਾ। ਕਿਰਪਾ ਕਰਕੇ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ।"
        }
    },
    otp: {
        line1: "ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ",
        line2: "ਅਸੀਂ ਤੁਹਾਡੇ ਮੋਬਾਈਲ ਨੰਬਰ 'ਤੇ ਪੁਸ਼ਟੀਕਰਨ ਕੋਡ ਭੇਜਿਆ ਹੈ",
        line3: "OTP ਨਹੀਂ ਮਿਲਿਆ?",
        line4: "ਜਾਰੀ ਰੱਖੋ",
        line5: "ਫੀਡਬੈਕ ਸੇਵਾ ਦੀ ਕਿਸਮ ਚੁਣੋ",
        line6: "ਨਗਦ ਜਮ੍ਹਾ",
        line7: "ਫੰਡ ਟ੍ਰਾਂਸਫਰ",
        line8: "ਖਾਤਾ ਖੋਲ੍ਹਣਾ",
        line9: "SB ਖਾਤਾ ਸੰਬੰਧੀ ਸੇਵਾਵਾਂ",
        line10: "ਮੌਜੂਦਾ ਖਾਤਾ",
        line11: "ਰੱਦ ਕਰੋ",
        line12: "ਅਗਲਾ",
        line13: "OTP ਦੁਬਾਰਾ ਭੇਜੋ",
        line14: "OTP ਸਫਲਤਾਪੂਰਕ ਭੇਜਿਆ ਗਿਆ",
        noTransactionTypes: "ਕੋਈ ਸੇਵਾ ਚੁਣਕੇ ਤੁਸੀਂ ਫੀਡਬੈਕ ਨਹੀਂ ਦੇ ਸਕਦੇ",
        error: {
            error: "OTP ਭੇਜਣ ਵਿੱਚ ਗਲਤੀ। ਕਿਰਪਾ ਕਰਕੇ ਬਾਅਦ ਵਿੱਚ ਦੁਬਾਰਾ ਕੋਸ਼ਿਸ਼ ਕਰੋ",
            otp: "ਗਲਤ OTP"
        }
    },
    feedback: {
        line1: "ਅੱਜ ਸਾਡੇ ਨਾਲ ਆਪਣੇ ਅਨੁਭਵ ਨੂੰ ਦਰਜ ਕਰੋ",
        verypoor: "ਬਹੁਤ ਖ਼ਰਾਬ",
        belowAverage: "ਸਧਾਰਣ ਤੋਂ ਘੱਟ",
        average: "ਸਧਾਰਣ",
        good: "ਚੰਗਾ",
        veryGood: "ਬਹੁਤ ਚੰਗਾ",
        excellent: "ਉਤਕ੍ਰਿਸ਼ਟ",        
        outstanding: "ਅਦਭੁਤ",
        poorFeedbackHeader: "ਅਸੀਂ ਅਸੰਤੁਸ਼ਟ ਹਾਂ, ਕਿਰਪਾ ਕਰਕੇ ਅਸੰਤੁਸ਼ਟੀ ਦੇ ਕਾਰਨ ਨੂੰ ਵੇਖੋ:",
        badService: "ਖ਼ਰਾਬ ਸੇਵਾ",
        notGoodSupport: "ਗਾਹਕ ਸਹਾਇਤਾ ਠੀਕ ਨਹੀਂ ਸੀ",
        others: "ਹੋਰ",
        textareaPlaceholder: "ਅਸੀਂ ਅਸੁਵਿਧਾ ਲਈ ਮਾਫ਼ੀ ਚਾਹੁੰਦੇ ਹਾਂ",
        captchaPlaceholder: "ਕੈਪਚਾ ਦਰਜ ਕਰੋ",
        reload: "ਕੈਪਚਾ ਰੀਲੋਡ ਕਰੋ",
        submit: "ਸਮਰਪਿਤ ਕਰੋ",
        work: "ਕਾਮ ਸਮੇਂ ਵਿੱਚ ਪੂਰੀ ਤਰ੍ਹਾਂ ਨਹੀਂ ਕੀਤਾ ਗਿਆ",
        inadequate: "ਉਤਪਾਦਾਂ/ਸੇਵਾਵਾਂ 'ਤੇ ਸਟਾਫ਼ ਨੂੰ ਠੀਕ ਗਿਆਨ ਨਹੀਂ ਸੀ",
        guidance: "ਕਾਮ ਕਰਨ ਲਈ ਸਹੀ ਦਿਸ਼ਾ-ਨਿਰਦੇਸ਼ ਨਹੀਂ ਦਿੱਤਾ ਗਿਆ",
        staff: "ਸਟਾਫ਼ ਦੀ ਵਰਤਾਵ",
        products: "ਉਤਪਾਦ/ਪ੍ਰਕਿਰਿਆਵਾਂ ਗਾਹਕ ਦੀਆਂ ਲੋੜਾਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਲਾਇਕ ਨਹੀਂ ਹਨ",
        other: "ਹੋਰ ਕੁਝ",
        general1: "ਕਾਮ ਨੂੰ ਸਥਿਰ ਤੌਰ 'ਤੇ ਪੂਰਾ ਨਹੀਂ ਕੀਤਾ ਗਿਆ",
        general2: "ਉਤਪਾਦ/ਸੇਵਾ 'ਤੇ ਸਟਾਫ਼ ਦਾ ਠੀਕ ਸਮਝ ਨਹੀਂ ਸੀ",
        general3: "ਕਾਮ ਕਰਨ ਲਈ ਸਹੀ ਦਿਸ਼ਾ-ਨਿਰਦੇਸ਼ ਨਹੀਂ ਦਿੱਤਾ ਗਿਆ",
        general4: "ਸਟਾਫ਼ ਦੀ ਵਰਤਾਵ",
        general5: "ਉਤਪਾਦ/ਪ੍ਰਕਿਰਿਆਵਾਂ ਗਾਹਕ ਦੀਆਂ ਲੋੜਾਂ ਨੂੰ ਪੂਰਾ ਕਰਨ ਲਈ ਸਹੀ ਨਹੀਂ ਹਨ",
        general6: "ਹੋਰ ਕੁਝ",
        error: {
            selectRating: "ਕਿਰਪਾ ਕਰਕੇ ਰੇਟਿੰਗ ਚੁਣੋ।",
            incorrectCaptcha: "ਗਲਤ ਕੈਪਚਾ।",
            submitFeedback: "ਪੁਸ਼ਟੀਕਰਨ ਦੇ ਬਿਨਾਂ ਤੁਸੀਂ ਫੀਡਬੈਕ ਨਹੀਂ ਦੇ ਸਕਦੇ",
            twice: "ਤੁਸੀਂ ਦੂਜੀ ਵਾਰ ਫੀਡਬੈਕ ਨਹੀਂ ਦੇ ਸਕਦੇ",
            transaction: "ਤੁਸੀਂ ਕਿਸੇ ਲੈਣ-ਦੇਣ ਤੋਂ ਪਹਿਲਾਂ ਫੀਡਬੈਕ ਨਹੀਂ ਦੇ ਸਕਦੇ"
        }
    },
    thankYou: {
        line1: "ਫੀਡਬੈਕ ਲਈ ਧੰਨਵਾਦ,",
        line2: "ਅਸੀਂ ਤੁਹਾਡੀ ਪ੍ਰਤਿਕ੍ਰਿਆ ਦੀ ਕਦਰ ਕਰਦੇ ਹਾਂ"
    },
    feedbackMessage: {
        line1: "ਅਸੀਂ ਪਹਿਲਾਂ ਹੀ ਤੁਹਾਡਾ ਫੀਡਬੈਕ ਪ੍ਰਾਪਤ ਕਰ ਚੁੱਕੇ ਹਾਂ",
        line2: "ਤੁਸੀਂ ਦੂਜੀ ਵਾਰ ਸਮਰਪਿਤ ਨਹੀਂ ਕਰ ਸਕਦੇ"
    },
    employee: {
        line1: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਕਰਮਚਾਰੀ ਕੋਡ ਦਰਜ ਕਰੋ",
        line2: "ਕਿਰਪਾ ਕਰਕੇ ਆਪਣਾ ਕਰਮਚਾਰੀ ਕੋਡ ਦਰਜ ਕਰੋ",
        line3: "ਕਰਮਚਾਰੀ ਕੋਡ",
        line4: "ਸਮਰਪਿਤ ਕਰੋ",
        error: {
            error1: "ਕਰਮਚਾਰੀ ਕੋਡ ਖਾਲੀ ਨਹੀਂ ਹੋ ਸਕਦਾ",
            error2: "ਇਸ ID ਨਾਲ ਕਰਮਚਾਰੀ ਨਹੀਂ ਮਿਲਿਆ",
            error3: "ਲੌਗਿਨ ਵੇਰਵੇ ਪ੍ਰਾਪਤ ਨਹੀਂ ਕੀਤੇ ਜਾ ਸਕਦੇ",
            error4 : "ਤੁਸੀਂ ਪੁਸ਼ਟੀਕਰਿਤ ਨਹੀਂ ਹੋ। ਕਿਰਪਾ ਕਰਕੇ ਪਹਿਲਾਂ ਲਾਗਿਨ ਕਰੋ।"
        }
    },
    salesforce: {
        line1: "ਸੇਲਜ਼ਫੋਰਸ ਨਾਲ ਲਾਗਿਨ ਕਰੋ",
        line2: "ਸੇਲਜ਼ਫੋਰਸ ਨਾਲ ਲਾਗਿਨ ਕਰੋ"
    },
    qr: "ਪਿਆਰੇ ਗਾਹਕੋ, ਤੁਹਾਡੇ ਫੀਡਬੈਕ ਦੀ ਮਹੱਤਵਪੂਰਣਤਾ ਹੈ! ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਬ੍ਰਾਂਚ ਬੈਂਕਿੰਗ ਅਨੁਭਵ ਦੇ ਬਾਰੇ ਆਪਣੇ ਫੀਡਬੈਕ ਨੂੰ ਸਾਨੂੰ ਭੇਜੋ ਤਾਂ ਕਿ ਅਸੀਂ ਤੁਹਾਨੂੰ ਬਿਹਤਰ ਸੇਵਾਵਾਂ ਦੇ ਸਕੀਏ"
}

export default punjabi;
