const urdu = {
    feedbackServiceFlow: {
        line1: "عمومی رائے",
        line2: "خدمت کی سطح کی رائے"
    },
    mobile: {
        line1: "اپنا موبائل نمبر درج کریں",
        line2: "ہم آپ کو ایک توثیقی کوڈ بھیجیں گے",
        line3: "موبائل نمبر درج کریں",
        line4: "جاری رکھیں",
        error: {
            error: "ایک خرابی پیش آگئی ہے۔ براہ کرم بعد میں دوبارہ کوشش کریں",
            otp: "OTP بھیجنے میں ناکامی۔ براہ کرم دوبارہ کوشش کریں۔"
        }
    },
    otp: {
        line1: "توثیقی کوڈ",
        line2: "ہم نے آپ کے موبائل نمبر پر توثیقی کوڈ بھیج دیا ہے",
        line3: "OTP موصول نہیں ہوا؟",
        line4: "جاری رکھیں",
        line5: "رائے کے لئے خدمت کی قسم منتخب کریں",
        line6: "رقم کی جمع",
        line7: "فنڈ کی منتقلی",
        line8: "اکاؤنٹ کھولنا",
        line9: "ایس بی اکاؤنٹ سے متعلق خدمات",
        line10: "موجودہ اکاؤنٹ",
        line11: "منسوخ کریں",
        line12: "اگلا",
        line13: "OTP دوبارہ بھیجیں",
        line14: "OTP کامیابی سے بھیجا گیا",
        noTransactionTypes: "کسی خدمت کے استعمال کے بغیر آپ رائے نہیں دے سکتے",
        error: {
            error: "OTP بھیجتے وقت خرابی ہوئی۔ براہ کرم بعد میں دوبارہ کوشش کریں",
            otp: "غلط OTP"
        }
    },
    feedback: {
        line1: "براہ کرم آج ہمارے ساتھ اپنے تجربے کی درجہ بندی کریں",
        verypoor: "بہت خراب",
        belowAverage: "اوسط سے کم",
        average: "اوسط",
        good: "اچھا",
        veryGood: "بہت اچھا",
        excellent: "شاندار",
        outstanding: "شاندار",
        poorFeedbackHeader: "ہمیں افسوس ہے، براہ کرم عدم اطمینان کی وجہ کی تفصیل دیں:",
        badService: "خراب خدمت",
        notGoodSupport: "صارف کی معاونت اچھی نہیں ہے",
        others: "دیگر",
        textareaPlaceholder: "ہمیں افسوس ہے",
        captchaPlaceholder: "کیپچا درج کریں",
        reload: "کیپچا دوبارہ لوڈ کریں",
        submit: "جمع کروائیں",
        work: "کام وقت پر مکمل نہیں ہوا",
        inadequate: "اسٹاف کے پاس پروڈکٹ/سروس کے بارے میں ناکافی علم",
        guidance: "کام کو مکمل کرنے کے لئے مناسب رہنمائی فراہم نہیں کی گئی",
        staff: "اسٹاف کا رویہ",
        products: "پروڈکٹس/پراسس صارفین کی ضروریات کو پورا کرنے کے لئے موزوں نہیں ہیں",
        other: "کوئی اور",
        general1: "کام تسلی بخش طریقے سے مکمل نہیں ہوا",
general2: "پروڈکٹ/سروس کے بارے میں اسٹاف کا ناکافی علم",
general3: "کام کرنے کے لیے مناسب رہنمائی فراہم نہیں کی گئی",
general4: "اسٹاف کا رویہ",
general5: "پروڈکٹس/عمل گاہکوں کی ضروریات پوری کرنے کے لیے مناسب نہیں ہیں",
general6: "کوئی اور",
        error: {
            selectRating: "براہ کرم درجہ بندی منتخب کریں۔",
            incorrectCaptcha: "کیپچا غلط ہے۔",
            submitFeedback: "تصدیق کے بغیر آپ رائے نہیں دے سکتے",
            twice: "آپ دو مرتبہ رائے نہیں دے سکتے",
            transaction: "لین دین کرنے سے پہلے آپ رائے نہیں دے سکتے"
        }
    },
    thankYou: {
        line1: "رائے دینے کے لئے شکریہ،",
        line2: "ہم آپ کے جواب کی قدر کرتے ہیں"
    },
    feedbackMessage: {
        line1: "ہم پہلے ہی آپ کی رائے وصول کر چکے ہیں",
        line2: "آپ دو مرتبہ جمع نہیں کر سکتے"
    },
    employee: {
        line1: "ملازم کوڈ درج کریں",
        line2: "براہ کرم اپنا ملازم کوڈ درج کریں",
        line3: "ملازم کوڈ",
        line4: "جمع کروائیں",
        error: {
            error1: "ملازم کوڈ خالی نہیں ہو سکتا",
            error2: "اوہ! کچھ غلط ہوگیا۔ براہ کرم ایڈمنسٹریٹر سے رابطہ کریں۔",
            error3: "لاگ ان کی تفصیلات حاصل کرنے میں ناکام",
            error4 : "آپ مستند نہیں ہیں۔ براہ کرم پہلے لاگ ان کریں۔",
            error5 : "اس آئی ڈی کے ساتھ ملازم کو تلاش نہیں کیا جا سکا"
        }
    },
    salesforce: {
        line1: "سیلز فورس کے ساتھ لاگ ان کریں",
        line2: "سیلز فورس کے ساتھ لاگ ان کریں"
    },
    qr : "پیارے صارفین، آپ کے تاثرات اہم ہیں! براہ کرم برانچ بینکنگ کے تجربے پر اپنی رائے جمع کروائیں تاکہ ہمیں آپ کی بہتر خدمت کرنے میں مدد ملے۔"
}

export default urdu;
