import {combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import LanguageReducer from './reducers/LanguageReducer';

const rootReducer = combineReducers({
  language: LanguageReducer
});

const store = configureStore({
    reducer : rootReducer
})

export default store;
