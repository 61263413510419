import React from "react";
import { setLanguage } from "../actions/LanguageAction";
import {connect } from "react-redux";

const SelectLang = ({ language, setLanguage }) =>{
    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
      };
    return(
        
         <select name="language" id="language" className="lang" onChange={handleLanguageChange} value={language} style={{fontSize:"13px"}}>
                  <option value="english">English</option>
                  <option value="hindi">हिन्दी</option>
                  <option value="tamil">தமிழ்</option>
                  <option value="telugu">తెలుగు</option>
                  <option value="malyalam">മലയാളം</option>
                  <option value="urdu">اردو</option>
                  <option value="kannada">ಕನ್ನಡ</option>
                  <option value="odiya">ଓଡ଼ିଆ</option>
                  <option value="bengali">বাংলা</option>
                  <option value="punjabi">ਪੰਜਾਬੀ</option>            
         </select>
    )
}

const mapStateToProps = (state) => ({
    language: state.language.language,
  });
  
  const mapDispatchToProps = {
    setLanguage,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(SelectLang);