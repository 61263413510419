import React, { useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import { v4 as uuidv4 } from 'uuid';
// require('dotenv').config();
import { useNavigate, useLocation } from 'react-router-dom';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import SelectLang from './SelectLang';
import useLanguage from '../languages/useLanguage';
import { useSelector } from 'react-redux';
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useEnglish from '../languages/useEnglish';

const Feedback = () => {
  const [isValid, setIsValid] = useState(true);
  const langs = useLanguage();
  const eng = useEnglish();
  const engFeedback = eng.feedback;
  const lang = langs.feedback;
  const choosenLang = useSelector((state)=>state.language.language);

  const API_ENDPOINT = process.env.REACT_APP_BANK_API_BASE_URL;
  const idsToCheck = [6, 12, 18, 24, 30, 36, 42,48,54,60,66,72,78,84,90,96,102,108,114,120];
  const [rating, setRating] = useState('');
  const [reason, setReason] = useState([]);
  const [comments, setComments] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [error, setError] = useState('');
  const [APIresponse, setAPIResponse] = useState({});
  const [questionData, setQuestionData] = useState('');
  const [questionId, setQuestionId] = useState(1);
  const [feedbackReasons, setFeedbackReasons] = useState([]);
  const [ratingKey, setRatingKey] = useState("");
  // const [fbType, setFbtype] = useState('');
  const navigate = useNavigate();
const location = useLocation();
const feedbackType = location.state?.feedbackType;
  useEffect(() => {
    loadCaptchaEnginge(4); 
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const uniqueKey = searchParams.get('id');
    // console.log(uniqueKey)
    if (uniqueKey) {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/checkTimestamp`, { unique_key : uniqueKey })
        .then(response => {
          console.log(response)
          if (response.data.valid === true) {
            setIsValid(true);
          } else {
            setIsValid(false);
            setError("Invalid Link")
            toast.error('The link is expired or invalid.');
          }
        })
        .catch(error => {
          console.error('API Error:', error);
          setIsValid(false);
          setError('An error occurred while validating the link.');
        });
    } 
  }, []);

  useEffect(() => {
    const fetchResponses = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/getReasons`, {
          language: choosenLang,
          service: searchParams.get('id') ? 'service' : feedbackType || 'general'
        });
        console.log('Responses:', response.data);
        setFeedbackReasons(response.data?.responses || [])
      } catch (error) {
        setFeedbackReasons([])
        console.error('Error fetching responses:', error);
        setError('Failed to load responses. Please try again.');
      }
    };
  
    fetchResponses();
  }, [choosenLang, feedbackType]);
  

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/getQuestions`, {
          type: feedbackType || 'general',
          language: choosenLang,
        });
        console.log(response)
        setQuestionId(response.data.questions[0].id)
        setQuestionData(response.data.questions[0].value);
      } catch (error) {
        console.error('Error fetching questions:', error);
        setError('Failed to load questions. Please try again.');
      }
    };

    fetchQuestions();
  }, [choosenLang, feedbackType, API_ENDPOINT])

  useEffect(()=>{
   console.log(reason);
  },[reason])

  const handleRatingChange = (selectedRating,ratingKey) => {
    setRating(selectedRating);
    setRatingKey(ratingKey);
    console.log(rating)
    setReason([]); 
  setComments(''); 
  setError('');
    
  };

  const handleReasonChange = (e) => {
    const { id, checked } = e.target;
    const idNumber = Number(id); // Ensure the id is handled as a number
    setReason((prevReasons) => {
      const newReasons = checked
        ? [...new Set([...prevReasons, idNumber])] // Use idNumber instead of id
        : prevReasons.filter((reason) => reason !== idNumber);
  
      console.log("Updated reasons:", newReasons);
      return newReasons;
    });
  };
  
  
  
  const validateUniqueID = async (uniqueKey) => {
    const searchParams = new URLSearchParams(window.location.search);
    const empCode = searchParams.get('emp_code')
    try {
      const feedbackType = location.state?.feedbackType || "general";
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/validate-uniquekey`, {
        uniqueKey: uniqueKey,
        feedbackType : feedbackType,
        empCode : empCode
      });
      console.log("response from validation",response)
      setAPIResponse(response);

      if (response.data.status === 'valid') {
        return true;
      } else if(response.data.status === 'invalid'){
        if(response.data.lastUpdated <1){
          // alert(lang.error.twice);
          navigate('/feedback-response')
        }
        else{
          toast.error(lang.error.transaction)
        }
        return false;
      }
      else{
        // alert(lang.error.twice);
        navigate('/feedback-response')
        return false;
      }
    } catch (error) {
      console.error('Error validating unique ID:', error);
      toast.error(lang.error.submitFeedback);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!rating) {
      setError('Please select a rating.');
      return;
    }
  
    // Validate reasons if rating is 'poor' or 'very poor'
    if ((rating === lang.average || rating === lang.belowAverage) && reason.length === 0) {
      setError('Please select at least one reason for your rating.');
      return;
    }
  
    // Validate comments if 'others' is selected
    if (reason.includes('others') && comments.trim() === '') {
      setError('Please provide details for the "Others" option.');
      return;
    }
  
    if (!validateCaptcha(captchaInput)) {
      setError(lang.error.incorrectCaptcha);
      loadCaptchaEnginge(4);
      setCaptcha('');
      return;
    }
    
    const searchParams = new URLSearchParams(location.search);
    let uniqueKey = searchParams.get('id');
    if (!uniqueKey) {
      uniqueKey = localStorage.getItem('uniqueKey');
    }
  
    if (!uniqueKey) {
      toast.error(lang.error.submitFeedback);
      return;
    }
  
    const isUniqueIDValid = await validateUniqueID(uniqueKey);
    if (!isUniqueIDValid) {
      return;
    }
  
    const customer_id = localStorage.getItem('customer_id') || "user1";
    const dp_code = localStorage.getItem('dp_code') || searchParams.get('dp_code');
    const transactionType = localStorage.getItem('transactionType') || "";
    const feedbackType = location.state?.feedbackType || "general";
    const phone_number = localStorage.getItem('phone_number');
    let feedback_category;
    if (feedbackType === 'employee') {
      feedback_category = 'QR';
    } else if (feedbackType === 'service') {
      feedback_category = 'transaction';
    } else if (searchParams.get('id')) {
      feedback_category = 'transaction';
    } else {
      feedback_category = 'kiosk';
    }
    // setFbtype(feedback_category);
    const requestBody = {
      Request: {
        body: {
          encryptData: {
            questions_id: questionId,
            user_id: customer_id || APIresponse?.data?.customer_id || "customer1",
            unique_id: uniqueKey,
            answer: rating,
            answer_english : engFeedback[ratingKey],
            language: choosenLang,
            user_type: feedbackType == 'employee' ? "employee" : "customer",
            feedback_category,
            feedback_type: searchParams.get('id') ? 'service' : feedbackType,
            dp_code: dp_code || APIresponse?.data?.dp_code || "dp001",
            transaction_type: transactionType || "general",
            mobile_number: phone_number,
            notification_opted: "false",
            free_text: (rating === lang.average || rating === lang.belowAverage) ? comments : '',
            other_opts : reason,
            emp_id : searchParams.get('emp_code')
          }
        }
      }
    };
    console.log(requestBody)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/saveFeedbackResponse`, requestBody);
      localStorage.clear()
      navigate(`/thank-you${location.search}`, { state: { fbType: feedback_category } });
    } catch (error) {
      console.error('API call failed:', error);
      setError('Something went wrong. Please try again.');
    }
  };
  
  
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!rating) {
  //     setError('Please select a rating.');
  //     return;
  //   }

  //   if (!validateCaptcha(captchaInput)) {
  //     setError(lang.error.incorrectCaptcha);
  //     loadCaptchaEnginge(4);
  //     setCaptcha('');
  //     return;
  //   }

  //   // Retrieve uniqueKey from local storage or URL
  //   let uniqueKey = localStorage.getItem('uniqueKey');
  //   const searchParams = new URLSearchParams(location.search);
  //   if (!uniqueKey) {
  //     uniqueKey = searchParams.get('id');
  //   }

  //   if (!uniqueKey) {
  //     toast.error(lang.error.submitFeedback);
  //     return;
  //   }

  //   const isUniqueIDValid = await validateUniqueID(uniqueKey);
  //   if (!isUniqueIDValid) {
  //     return;
  //   }

  //   const customer_id = localStorage.getItem('customer_id') || "user1";
  //   const dp_code = localStorage.getItem('dp_code') || "dp001";
  //   const transactionType = localStorage.getItem('transactionType') || "";
  //   const feedbackType = location.state?.feedbackType || "general";
  //   const phone_number = localStorage.getItem('phone_number');
  //   const requestBody = {
  //     Request: {
  //       body: {
  //         encryptData: {
  //           questions_id: "1",
  //           user_id: customer_id || APIresponse?.data?.customer_id || "customer1",
  //           unique_id: uniqueKey,
  //           answer: rating,
  //           language: choosenLang,
  //           user_type: !searchParams.get('id') && !feedbackType ? "employee" : "customer",
  //           feedback_category: feedbackType ? "kiosk" : searchParams.get('id') ? "transaction" : "QR",
  //           feedback_type: feedbackType || "general",
  //           dp_code: dp_code || APIresponse?.data?.dp_code || "dp001",
  //           transaction_type: transactionType || "general",
  //           mobile_number: phone_number,
  //           notification_opted: false,
  //           free_text: (rating === 'poor' || rating === 'very-poor') ? comments : '', // Include free_text if rating is poor or very poor
  //         }
  //       }
  //     }
  //   };
  //   console.log(requestBody)
  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/saveFeedbackResponse`, requestBody);
  //     navigate('/thank-you');
  //   } catch (error) {
  //     console.error('API call failed:', error);
  //     setError('Something went wrong. Please try again.');
  //   }
  // };
  return  (
    <>
    {
    <div>
      <section className="headerBox blue-bg">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 pt-2 pb-2 top">
                <a href="#">
                  <img src="images/logo.png" alt="Canara Bank"/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid langCol">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 bottom pt-1 pb-1 top">
                <SelectLang />
              </div>
            </div>
          </div>
        </div>
      </section>

      {<section className="formBox">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 inner pb-4">
                <div className="col-sm-7">
                  <div className="col-lg-12 ratingCol">
                   
                    <h3>{questionData}</h3>
                    <div className="col-sm-12 rate">
                      <span>{lang.selectRating}</span>
                      <ul className="list-inline">
                      <li className={`list-inline-item ${rating === lang.excellent ? 'highlight' : ''}`} onClick={() => handleRatingChange(lang.excellent,'Excellent')}>
                          <figure>
                            <img src="images/very-good.png" alt={lang.excellent} height={"40px"} width={"40px"} />
                          </figure>
                          <b>{lang.excellent}</b>
                        </li>
                        <li className={`list-inline-item ${rating === lang.veryGood ? 'highlight' : ''}`} onClick={() => handleRatingChange(lang.veryGood,'veryGood')}>
                          <figure>
                            <img src="images/good.png" alt={lang.veryGood} height={"40px"} width={"40px"} />
                          </figure>
                          <b>{lang.veryGood}</b>
                        </li>
                        <li className={`list-inline-item ${rating === lang.good ? 'highlight' : ''}`} onClick={() => handleRatingChange(lang.good,'good')}>
                          <figure>
                            <img src="images/average.png" alt={lang.good} height={"40px"} width={"40px"} />
                          </figure>
                          <b>{lang.good}</b>
                        </li>
                        <li className={`list-inline-item ${rating === lang.average ? 'highlight' : ''}`} onClick={() => handleRatingChange(lang.average,'average')}>
                          <figure>
                            <img src="images/poor.png" alt={lang.average} height={"40px"} width={"40px"}  />
                          </figure>
                          <b>{lang.average}</b>
                        </li>
                        <li className={`list-inline-item ${rating === lang.belowAverage  ? 'highlight' : ''}`} onClick={() => handleRatingChange(lang.belowAverage,'Below Average')}>
                          <figure>
                            <img src="images/very-poor.png" alt={lang.belowAverage} height={"40px"} width={"40px"}/>
                          </figure>
                          <b>{lang.belowAverage}</b>
                        </li>
                        {/* <li className={`list-inline-item ${rating === lang.verypoor ? 'highlight' : ''}`} onClick={() => handleRatingChange(lang.verypoor,'verypoor')}>
                          <figure>
                            <img src="images/very-poor.png" alt={lang.verypoor} height={"40px"} width={"40px"}/>
                          </figure>
                          <b>{lang.verypoor}</b>
                        </li> */}
                      </ul>
                    </div>

                    {(rating === lang.average || rating === lang.belowAverage) && (
    <div className="col-lg-12 badQue">
      <h4>{lang.poorFeedbackHeader}</h4>
      <form>
      {feedbackReasons.map((reasonItem) => {
  
  return (
    <div className="form-group" key={reasonItem.id}>
      <input
        type="checkbox"
        id={reasonItem.id}
        onChange={handleReasonChange}
        checked={reason.includes(reasonItem.id)}
      />
      <label htmlFor={reasonItem.id}>
        {reasonItem.value}
      </label>
    </div>
  );
})}
        {idsToCheck.some(id => reason.includes(id)) && (
  <div className="form-group">
    <textarea
      className="form-control"
      value={comments}
      rows={10}
      cols={100}
      onChange={(e) => setComments(e.target.value)}
      maxLength="5000"
    ></textarea>
  </div>
)}
      </form>
    </div>
  )}
                    <div className="col-lg-12 captcha">
                      <LoadCanvasTemplate reloadText={lang.reload}/>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder={lang.captchaPlaceholder}
                        value={captchaInput}
                        onChange={(e) => setCaptchaInput(e.target.value)}
                      />
                    </div>
                  </div>

                  {error && <p className="text-danger">{error}</p>}
                  <div className="col-sm-12">
                  <button
  onClick={handleSubmit}
  className="button submitBtn btn btn-primary mt-3"
  disabled={
    !rating || 
    !captchaInput || 
    !isValid || 
    (idsToCheck.some(id => reason.includes(id)) && comments === '') ||
    ((rating === lang.average || rating === lang.belowAverage) && reason.length === 0)
  }
>
                      {lang.submit}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>}

      <footer className="footerBox mt-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 p-0">
              <p>@2024, Canara Bank, All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div> }
    </>
  );
};

export default Feedback;
