const hindi = {
    feedbackServiceFlow : {
        line1 : "सामान्य प्रतिक्रिया",
        line2 : "सेवा स्तर प्रतिक्रिया"
    },
    mobile : {
        line1 : "अपना मोबाइल नंबर दर्ज करें",
        line2 : "हम आपको एक सत्यापन कोड भेजेंगे",
        line3 : "मोबाइल नंबर दर्ज करें",
        line4 : "जारी रखें",
        error :{
            error : "एक त्रुटि पाई गई। कृपया बाद में पुन: प्रयास करें",
            otp : "ओटीपी भेजने में विफल. कृपया पुन: प्रयास करें।"
        }
    },
    otp : {
        line1 : "सत्यापन कोड",
        line2 : "हमने आपके मोबाइल नंबर पर सत्यापन कोड भेजा है",
        line3 : "OTP प्राप्त नहीं हुआ?",
        line4 : "जारी रखें",
        line5: "कृपया प्रतिक्रिया के लिए सेवा प्रकार का चयन करें",
    line6: "नकद जमा",
    line7: "धन हस्तांतरण",
    line8: "खाता खोलना",
    line9: "एसबी खाता संबंधित सेवाएं",
    line10: "चालू खाता",
    line11: "रद्द करें",
    line12: "अगला",
    line13 : "ओटीपी पुनः भेजें",
    line14 : "ओटीपी सफलतापूर्वक भेजा गया",
        error :{
            error : "एक त्रुटि पाई गई। कृपया बाद में पुन: प्रयास करें",
            otp : "अमान्य ओटीपी. कृपया पुन: प्रयास करें।"
        }
       
    },
    feedbackMessage : {
        line1 : "हमें आपकी प्रतिक्रिया पहले ही मिल चुकी है",
        line2 : "आप दो बार सबमिट नहीं कर सकते"
    },
    feedback : {
        line1 : "कृपया आज हमारे साथ अपने अनुभव को रेट करें",
        verypoor : "बहुत खराब",
        belowAverage: "औसत से कम",
        average: "औसत",
        good: "अच्छा",
        veryGood: "बहुत अच्छा",
        excellent: "उत्कृष्ट",
        outstanding : "उत्कृष्ट",
        poorFeedbackHeader : "हमें असुविधा के लिए खेद है, कृपया असंतोष का कारण बताएं:",
        badService : "खराब सेवा",
        notGoodSupport : "ग्राहक सहायता अच्छी नहीं",
        others : "अन्य",
        textareaPlaceholder : "हमें असुविधा के लिए खेद है",
        captchaPlaceholder : "कैप्चा दर्ज करें",
        reload : "कैप्चा पुनः लोड करें",
        submit : "प्रस्तुत करें",
        noTransactionTypes : "आप किसी भी सेवा का उपयोग किए बिना प्रतिक्रिया सबमिट नहीं कर सकते",
        submit: "जमा करें",
        work: "काम समय पर पूरा नहीं हुआ",
        inadequate: "उत्पाद/सेवा पर स्टाफ का अपर्याप्त ज्ञान",
        guidance: "काम पूरा करने के लिए उचित मार्गदर्शन नहीं मिला",
        staff: "स्टाफ का व्यवहार",
        products: "उत्पाद/प्रक्रिया ग्राहकों की जरूरतों को पूरा करने के लिए उपयुक्त नहीं है",
        other: "कोई अन्य",
        general1: "काम संतोषजनक रूप से पूरा नहीं हुआ",
        general2: "उत्पाद/सेवा के बारे में कर्मचारियों का अपर्याप्त ज्ञान",
        general3: "काम को पूरा करने के लिए कोई उचित मार्गदर्शन नहीं दिया गया",
        general4: "कर्मचारियों का व्यवहार",
        general5: "उत्पाद/प्रक्रिया ग्राहकों की जरूरतों को पूरा करने के लिए उपयुक्त नहीं है",
        general6: "कोई और",
        error : {
            selectRating : "कृपया रेटिंग चुनें।",
            incorrectCaptcha : "कैप्चा गलत है।",
            submitFeedback : "आप प्रमाणीकरण के बिना प्रतिक्रिया नहीं दे सकते",
            twice : "आप दो बार प्रतिक्रिया नहीं दे सकते",
            transaction :"आप कोई भी लेनदेन करने से पहले प्रतिक्रिया नहीं दे सकते"
        },
        header: {
            logoAlt: "केनरा बैंक का लोगो"
        },
        footer: {
            rightsReserved: "@2024, केनरा बैंक, सर्वाधिकार सुरक्षित।"
        },
        validation: {
            noFeedbackBeforeTransaction: 'आप लेन-देन किए बिना प्रतिक्रिया नहीं दे सकते',
            feedbackTwice: 'आप प्रतिक्रिया दो बार नहीं दे सकते।',
            invalidUniqueID: 'आप प्रतिक्रिया प्रस्तुत नहीं कर सकते'
        }
    },
    thankYou : {
        line1 : "प्रतिक्रिया के लिए धन्यवाद,",
        line2 : "हम आपके उत्तर की सराहना करते हैं"
    },
    employee: {
        line1: "कर्मचारी कोड दर्ज करें",
        line2: "कृपया अपना कर्मचारी कोड दर्ज करें",
        line3: "कर्मचारी कोड",
        line4: "सबमिट करें",
        error : {
            error1 : "कर्मचारी कोड खाली नहीं हो सकता",
            error2 : "ओह! कुछ गलत हो गया। कृपया व्यवस्थापक से संपर्क करें।",
            error3 : "लॉगिन विवरण लाने में असमर्थ",
            error4 : "आप प्रमाणित नहीं हैं। कृपया पहले लॉगिन करें।",
            error5 : "इस आईडी वाला कर्मचारी ढूंढने में असमर्थ"
        }
    },
    salesforce : {
        line1 : "सेल्सफोर्स के साथ लॉगिन करें",
        line2 : "सेल्सफोर्स के साथ लॉगिन करें"
    },
    qr : "प्रिय ग्राहक, आपका सुझाव हमारे लिए महत्वपूर्ण है। कृपया शाखा में अपने बैंकिंग अनुभव पर प्रतिक्रिया दे ताकि हम आपको बेहतर सेवा दे सके।"
}

export default hindi;
